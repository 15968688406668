import '../styles/login/loginSignUp.css';
import { useEffect, useState } from 'react';
import SignUp from '../components/SignUp/SignUp';
import Login from '../components/User/Login';
import { useAppDispatch, useAppSelector } from '../store';
import { fetchGoogleAuth } from '../store/features/auth/google.get.token.slice';
import { fetchMicrosoftAuth } from '../store/features/auth/microsoft.get.token.slice';
import { resetError } from '../store/features/auth/auth.post.slice';
import Footer from '../components/Footer/Footer';
import ELogo from "../assets/Veewer_black_stripes_E.png"


export default function LoginSignUp() {
	const [activeLoginAction, setActiveLoginAction] = useState(false);
	const [activeSignUpAction, setActiveSignUpAction] = useState(true);
	const [activeAction, setActiveAction] = useState('signup');
	const getGoogleTokenSelector = useAppSelector((x) => x.googleAuthSlice)
	const getMicrosoftTokenSelector=useAppSelector((x)=>x.microsoftAuthSlice)
	const postRegisterSelector = useAppSelector((x) => x.fetchUserRegisterSlice);
	const loginSelector = useAppSelector((x) => x.postAuth);
	const dispatch = useAppDispatch()
	//const bucket=sessionStorage.getItem("b") as string

	function transitionToLogin() {
		setActiveLoginAction(true);
		setActiveSignUpAction(false);
		setActiveAction('login');
	}

	function transitionToSignUp() {
		if(loginSelector.error!==null){
			dispatch(resetError())
		}
		setActiveSignUpAction(true);
		setActiveLoginAction(false);
		setActiveAction('signup');
	}

	

	useEffect(() => {
		if (window.location.href.indexOf('?code') !== -1) {
			if (window.location.href.indexOf('google') !== -1) {
				const code = window.location.href
					.split('/login?')[1]
					.split('=')[1]
					.split('&')[0];

				dispatch(fetchGoogleAuth({
					code
				})).then(()=>{				
					if(getGoogleTokenSelector!=null && getGoogleTokenSelector.data!==null){
						sessionStorage.setItem("login",JSON.stringify({
							isAuthenticated: getGoogleTokenSelector.data.isAuthenticated,
							expiresAt: getGoogleTokenSelector.data.expiresAt,
						    isAdmin:getGoogleTokenSelector.data.isAdmin,
							isOwner:getGoogleTokenSelector.data.isOwner,
							isUser:getGoogleTokenSelector.data.isUser,
							uid: getGoogleTokenSelector.data.uid,
							microsoft_auth:false,
							google_auth:getGoogleTokenSelector.data.google_auth
						}))
					}			
					sessionStorage.setItem("google-auth",JSON.stringify({
						isSuccess:true
					}))
					transitionToLogin()
				})
			}
			else {
				const code = window.location.href
					.split('/login?')[1]
					.split('=')[1]
					.split('&')[0];

				dispatch(fetchMicrosoftAuth({
					code
				})).then(()=>{
					if(getMicrosoftTokenSelector!=null && getMicrosoftTokenSelector.data!==null){
						sessionStorage.setItem("login",JSON.stringify({
							isAuthenticated: getMicrosoftTokenSelector.data.isAuthenticated,
							expiresAt: getMicrosoftTokenSelector.data.expiresAt,
							isAdmin: getMicrosoftTokenSelector.data.isAdmin,
							isOwner: getMicrosoftTokenSelector.data.isOwner,
                            isUser: getMicrosoftTokenSelector.data.isUser,
							uid: getMicrosoftTokenSelector.data.uid,
							microsoft_auth:getMicrosoftTokenSelector.data.microsoft_auth,
							google_auth:false
						}))
					}			
					sessionStorage.setItem("microsoft-auth",JSON.stringify({
						isSuccess:true
					}))
					transitionToLogin()
				})
			}
		}
	}, [])

	return (
		<>
		<div className='page-wrapper'>
		
			<div className='login-signup-container min-h-[70vh] mb-10'>
				<div
					className='login-signup-header'
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '10px',
						marginBottom: '20px',
					}}
				>
					<img
						src={ELogo}
						alt='sync3d-logo'
						width='45px'
						height='45px'
					/>

					<h1
						className='action-title title'
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							margin: '0',
						}}
					>
						{activeAction === 'signup' ? (
							'Sign Up'
						) : activeAction === 'login' ? (
							'Login'
						) : (
							<> </>
						)}
					</h1>
				</div>

				{!postRegisterSelector.loading && !loginSelector.loading ? (
					<div className='container-wrapper'>
						<div className='choose-action'>
							<div className='action-container'>
								<div
									onClick={transitionToSignUp}
									className={`action signup ${activeSignUpAction &&
											!activeLoginAction &&
											activeAction === 'signup'
											? 'active'
											: ''
										}`}
								>
									<span>Sign Up</span>
								</div>
								<div
									onClick={transitionToLogin}
									className={`action login ${activeLoginAction && !activeSignUpAction ? 'active' : ''
										}`}
								>
									<span>Login</span>
								</div>
							</div>
						</div>
					</div>
				) : (
					<></>
				)}

				{activeAction === 'signup' ? (
					<SignUp setActiveAction = {setActiveAction}/>
				) : (
					<Login />
				)}
			</div>
		</div>
		<Footer/>
		</>
	);
}