import { GetContentRootPathRequestModel, GetContentRootPathResponseModel } from "../../models/file/get.content.root.path.model";
import { BasePostService } from "../base/base.post.service";
import { BasePutService } from "../base/base.put.service";


// export class GetContentRootPathService extends BasePutService<GetContentRootPathRequestModel,GetContentRootPathResponseModel>{
//     constructor(controllerName:string="files", endpoint:string="rootPath"){
//         super(controllerName, endpoint)
//     }

//         async GetContentRoot(request: GetContentRootPathRequestModel) {
//         const formData = new FormData();
//         formData.append("file", request.file);
//         formData.append("rootFileName", request.rootFileName || '');
//         formData.append("bucketName",request.bucketName || '')
//         formData.append("bytesRead",request.bytesRead.toString())
//         formData.append("dataWrited",request.dataWrited.toString())
//         formData.append("progress",request.progress.toString())
//         formData.append("startByte",request.startByte.toString())
//         formData.append("saveFileName",request.saveFileName || '')
//         formData.append("saveFilePath",request.saveFilePath || '')
//         formData.append("fileSize",request.fileSize.toString())
    

//         return await this.PutWithFormDataAsync(formData);
//     }
// }


export class GetContentRootPathService extends BasePutService<GetContentRootPathRequestModel,GetContentRootPathResponseModel>{
    constructor(controllerName:string="files", endpoint:string="rootPath"){
        super(controllerName, endpoint)
    }

    async GetContentRoot(request: GetContentRootPathRequestModel) {
        const formData = new FormData();
        formData.append("file", request.file);
        formData.append("rootFileName", request.rootFileName || '');
        formData.append("bucketName",request.bucketName || '')
        formData.append("bytesRead",request.bytesRead.toString())
        formData.append("totalBytes",request.totalBytes.toString())

        return await this.PutWithFormDataAsync(formData);
    }
}