import { Link } from 'react-router-dom';
import '../styles/email/confirmed.scss'; // Optional: For custom styling

const EmailConfirmed = () => {
    return (
        <div className="email-confirmed-container">
        <div className="email-confirmed-card">
            <div className="email-confirmed-header">
                <h1>🎉 Email Confirmed!</h1>
            </div>
            <div className="email-confirmed-body">
                <p>
                    Your email has been successfully confirmed. We're thrilled to have you with us! 
                    You can now log in to your account and start exploring all the features we have to offer.
                </p>
            </div>
            <div className="email-confirmed-footer">
                <Link to="/login" className="login-button">
                    Go to Login
                </Link>
            </div>
        </div>
    </div>
    );
};

export default EmailConfirmed;
