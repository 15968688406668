import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlushed } from '@fortawesome/free-regular-svg-icons';
import CardItem from '../../components/CardItem';
import ConfirmModal from '../../components/Modals/ConfirmDeleteModal';
import MultipleUploadModal from '../../components/MultipleUploadModal/MultipleUploadModal';
import TextAreaModal from '../../components/Modals/TextAreaModal';
import Unauthorized from '../../components/Unauthorized';
import { GetSubUploadedObjectResponseModel, GetUploadedObjectResponseModel } from '../../models/object/uploadedObject/get.uploadedObject.response.model';
import { GetUploadedObjectHtmlContentService } from '../../services/uploadedObject/get.uploadedObject.html.content.service';
import { GetUploadedObjectModelViewService } from '../../services/uploadedObject/get.uploadedObject.model.view.service';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { getUploadedObjectByUserId, getUploadedObjects, updateWithDeleteObject } from '../../store/features/object/get.list.uploadedObject.slice';
import IAM from '../../assets/IAM.png';
import IPT from '../../assets/IPT.png';
import ZIP from '../../assets/zip.svg';
import DWFX from '../../assets/DWFX.png'
import DWG from '../../assets/DWG.jpg';
import FBX from '../../assets/FBX.png';
import MODEL from '../../assets/MODEL.png';
import MAX from '../../assets/MAX.png';
import STL from '../../assets/STL.png'
import STP from '../../assets/STP.png'
import SLDASM from '../../assets/SLDASM.png'
import RVT from '../../assets/RVT.jpg'
import DWF from '../../assets/DWF.png'
import F3D from '../../assets/f3d.png'
import GLB from '../../assets/GLB.png'
import OBJ from '../../assets/obj.png'
import PSM from '../../assets/psm.png'
import STEP from "../../assets/STEP.png"
import { baseUrl } from '../../config/axios.config';
import '../../styles/objectList/objectList.css';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { LoginResponse } from '../../models/auth/login.model';
import { deleteUploadedObject } from '../../store/features/object/delete.object.slice';
import { UploadedFile } from '../../models/object/uploadedObject/put.uploadedObject.request.model';
import JSZip from 'jszip';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import LoadingScreen from '../../utils/LoadingScreen';
import { checkCreditUploadedObject, resetCreditError } from '../../store/features/object/check.credit.slice';
import { checkCreditUploadedObjectWhenUpload } from '../../store/features/object/check.credit.when.upload.slice';
import ChangeNameModal from '../../components/Modals/ChangeNameModal';
import { updateUploadedObjectName } from '../../store/features/object/update.uploaded.object.name.slice';
import { DeleteModelsService } from '../../services/file/delete.models.service';
import { CheckManifestUploadedObjectService } from '../../services/uploadedObject/check.manifest.uploadeObject.service';
import { ProcessingUploadModel, ProcessUploadIterationResponseModel } from '../../models/object/uploadedObject/uploadV2/processing.upload.model';
import { FinalizeUploadModelsService } from '../../services/uploadedObject/uploadV2/finalize.upload.models.service';
import { ProcessingUploadModelService } from '../../services/uploadedObject/uploadV2/processing.upload.model.service';
import { GetContentRootPathService } from '../../services/file/get.content.root.path.service';
import { resetCreateState } from '../../store/features/object/create.uploadedObject.slice';


export interface SelectableZipFiles {
  mainZipFile: File
  subFiles: SelectableSubFiles[]

}

export interface SelectableSubFiles {
  fileName: string
  fileSize: number,
  file: File
}

const ObjectList: React.FC = () => {
  const [uploadModal, setUploadModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [webSite, setWebSite] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedObjectId, setSelectedObjectId] = useState('');
  const [textAreaModal, setTextAreaModal] = useState(false);
  const [fileLimit, setFileLimit] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<UploadedFile[]>([]);
  const [selectedSearchValue, setSelectedSearchValue] = useState('');
  const [selectedDeleteValue, setSelectedDeleteValue] = useState('');
  const [zipFile, setZipFile] = useState<SelectableZipFiles | undefined>(undefined)
  const [openZipOption, setOpenZipOption] = useState(false);
  const [extractZipFileStatus, setExtractZipFileStatus] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const objectListSelectorByUserId = useAppSelector((s: RootState) => s.getUploadedObjectByUserId)
  const selectedObject = useAppSelector((s: RootState) => s.getUploadedObject);
  const selectedObjectByBucket = useAppSelector((s: RootState) => s.getUploadedObjectByBucketAndName);
  const deleteSelectedObject = useAppSelector((s: RootState) => s.deleteObjectSlice)

  const bucketKey = sessionStorage.getItem('b') as string;
  const loginInfo = sessionStorage.getItem('login') as string;
  const jsonLoginInfo = JSON.parse(loginInfo) as LoginResponse
  const [openSupportedFormats, setOpenSupportedFormats] = useState(false);
  const supportedFormats = [
    "3DM", "3DS", "A", "ASM", "AXM", "BRD", "CATPART", "CATPRODUCT", "CGR", "COLLABORATION", "DAE", "DDX", "DDZ", "DGK",
    "DGN", "DLV3", "DMT", "DWF", "DWFX", "DWG*", "DWT", "DXF", "EMODEL", "EXP", "F3D", "FBRD", "FBX", "FSCH", "G", "GBXML",
    "GLB", "GLTF", "IAM", "IDW", "IFC", "IGE", "IGES", "IGS", "IPT", "IWM", "JT", "MAX", "MODEL", "MPF", "MSR", "NEU",
    "NEW", "NWC", "NWD", "OBJ", "OSB", "PAR", "PMLPRJ", "PMLPRJZ", "PRT", "PSM", "PSMODEL", "RVM", "RVT**", "SAB", "SAT",
    "SCH", "SESSION", "SKP", "SLDASM", "SLDPRT", "SMB", "SMT", "STE", "STEP", "STL", "STLA", "STLB", "STP", "STPZ", "VPB",
    "VUE", "WIRE", "X_B", "X_T", "XAS", "XPR"
  ];
  const actualSupportedFormats = [
    "3DM", "3DS", "A", "ASM", "AXM", "BRD", "CATPART", "CATPRODUCT", "CGR", "COLLABORATION", "DAE", "DDX", "DDZ", "DGK",
    "DGN", "DLV3", "DMT", "DWF", "DWFX", "DWG", "DWT", "DXF", "EMODEL", "EXP", "F3D", "FBRD", "FBX", "FSCH", "G", "GBXML",
    "GLB", "GLTF", "ZIP", "IDW", "IFC", "IGE", "IGES", "IGS", "IPT", "IWM", "JT", "MAX", "MODEL", "MPF", "MSR", "NEU",
    "NEW", "NWC", "NWD", "OBJ", "OSB", "PAR", "PMLPRJ", "PMLPRJZ", "PRT", "PSM", "PSMODEL", "RVM", "RVT", "SAB", "SAT",
    "SCH", "SESSION", "SKP", "SLDPRT", "SMB", "SMT", "STE", "STEP", "STL", "STLA", "STLB", "STP", "STPZ", "VPB",
    "VUE", "WIRE", "X_B", "X_T", "XAS", "XPR"
  ];
  const [invalidFileErrorMessage, setInvalidFileErrorMessage] = useState("")
  const checkFileExtension = (file: File): boolean => {
    const fileExtension = file.name.split('.').pop()?.toUpperCase();
    return fileExtension ? actualSupportedFormats.includes(fileExtension) : false;
  };

  const MAX_COUNT = 15;
  const UploadFilesSwal = withReactContent(Swal);
  const [creditWarnings, setCreditWarnings] = useState("")
  const [openChangeModal, setOpenChangeModal] = useState(false)

  const [isFinishedToLoading, setIsFinishedToLoading] = useState(false);
  const [startToGetChunkStatus, setStartToGetChunkStatus] = useState(false);
  const [processToUpload, setProcessToUpload] = useState(false);
  const startUploadModelsSelector = useAppSelector((s: RootState) => s.startUploadModelsSlice)
  const [chunks, setChunks] = useState<ProcessingUploadModel[]>([])
  const [manifestStatus, setManifestStatus] = useState("");
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState("");
  const [steppedFileName, setSteppedFileName] = useState("")
  const [afterUploadError, setAfterUploadError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false); // Mevcut işlem kontrolü
  const [waitingForManifest, setWaitingForManifest] = useState(true); // Manifest bekleme durumu
  const [currentUploadIndex, setCurrentUploadIndex] = useState(0); //


  const processZipFile = async (file: File) => {
    const zip = new JSZip();
    const zipContent = await zip.loadAsync(file);
    let subFiles: SelectableSubFiles[] = [];
    setExtractZipFileStatus(true)
    for (const relativePath of Object.keys(zipContent.files)) {
      const f = zipContent.files[relativePath];
      if (!f.dir) {
        const fileName = f.name.split('/').pop() as string;
        const fileSizeInKB = ((f as any)._data.uncompressedSize / 1024).toFixed(2);

        const fileBlob = await f.async("blob");
        const file = new File([fileBlob], fileName, { type: fileBlob.type });

        if (true) {
          if (file.name.includes("\\")) {
            var splittedFileName = file.name.split("\\");
            var actualFileName = splittedFileName[splittedFileName.length - 1]
            subFiles.push({
              file,
              fileName: actualFileName,
              fileSize: Number(fileSizeInKB)
            });
          } else {
            subFiles.push({
              file,
              fileName,
              fileSize: Number(fileSizeInKB)
            });
          }

        }
      }
    }
    setZipFile(() => {
      return {
        mainZipFile: file,
        subFiles
      };
    });
    setExtractZipFileStatus(false)
    return zipFile;
  };

  const handleUploadFiles = (chosenFiles: File[]) => {
    let uploaded = [...selectedFiles];
    let limitedExceeded = false;
    chosenFiles.map((file, i) => {
      if (uploaded.findIndex((f) => f.file.name === file.name) === -1) {
        const invalidFile = checkFileExtension(file);
        console.log(invalidFile)
        if (invalidFile === false) {
          if (file.name.endsWith(".iam") || file.name.endsWith(".sldasm") || file.name.endsWith(".SLDASM")) {
            setInvalidFileErrorMessage("Assembly type files should be included in the zip together with their part files and uploaded.")
          }
          else {
            setInvalidFileErrorMessage(`Invalid file format(s): ${file.name}. Please upload files with supported formats.`);
          }

        }
        else {
          dispatch(checkCreditUploadedObjectWhenUpload({
            bucketName: bucketKey,
            fileType: file.name
          })).then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
              if (file.name.endsWith('.zip')) {
                setOpenZipOption(true)
              }
              uploaded.push({
                file: file,
                rootFileName: ""
              });

              if (uploaded.length === MAX_COUNT) setFileLimit(true);
              if (uploaded.length > MAX_COUNT) {
                UploadFilesSwal.fire({
                  title: <h3>Maximum upload file limit warning</h3>,
                  didOpen: () => {
                    UploadFilesSwal.showLoading();
                  },
                }).then(() => {
                  UploadFilesSwal.fire(
                    <p>{`You can only add a maximum of ${MAX_COUNT} files`}</p>
                  );
                });
                setFileLimit(false);
                limitedExceeded = true;
                return true;
              }
              if (!limitedExceeded) {
                console.log(uploaded);
                setSelectedFiles((prevFiles) => {
                  uploaded.map((u, i) => {
                    if (!prevFiles.includes(u)) {
                      prevFiles.push(u)
                    }
                  })
                  return [...prevFiles]
                });
                //setSelectedFiles(uploaded)        
                setUploadModal(true);
              }
              console.log(selectedFiles)
            }
            else {
              // console.log(checkCreditWhenUploadSelector.error)
            }

          }).catch((error) => {
            console.log(error)
          })


        }
      }
    });
  }

  const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleCloseModal = () => {
    setUploadModal(false);
    setSelectedFiles([]);
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = Array.prototype.slice.call(event.dataTransfer.files);
    handleUploadFiles(files);
  };

  const handleFileInputWithDrop = async (e: React.DragEvent<HTMLDivElement>): Promise<void> => {
    e.preventDefault()
    const files: File[] = Array.from(e.dataTransfer.files || []);
    handleFileDrop(e);
    for (const file of files) {
      console.log(file.name)
      if (file.name.endsWith('.zip')) {
        await processZipFile(file);
      }
    }

    e.dataTransfer.clearData()
  }

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files: File[] = Array.from(e.target.files || []);
    handleFileEvent(e);
    for (const file of files) {
      console.log(file.name)
      if (file.name.endsWith('.zip')) {
        await processZipFile(file);
      }

    }
    e.target.value = '';
    console.log(selectedFiles)
  };

  const getChunksAsync = async (file: UploadedFile): Promise<ProcessingUploadModel | null> => {

    if (file !== undefined) {
      setStep("Preparing to upload...")
      setStartToGetChunkStatus(true)
      const service = new GetContentRootPathService()

      const firstChunkSize = 81920;
      let progress = 0;
      let bytesRead = 0;
      const totalBytes = file.file.size;
      let firstFileName = ""
      let firstPath = ""
      let completed = false

      while (completed === false) {
        const startByte = bytesRead;
        const endByte = Math.min(startByte + firstChunkSize, totalBytes);
        if (startByte >= totalBytes) {
          console.error("Start byte exceeds total bytes. Breaking the loop.");
          break;
        }
        const chunkBlob = file.file.slice(startByte, endByte);
        if (chunkBlob.size === 0) {
          console.error("Chunk blob size is zero. Breaking the loop.");
          break;
        }
        const chunkFile = new File([chunkBlob], file.file.name, { type: file.file.type });
        const response = await service.GetContentRoot({
          rootFileName: file.rootFileName || "",
          file: chunkFile,
          bucketName: bucketKey,
          totalBytes: totalBytes,
          bytesRead: bytesRead,
        });
        bytesRead = response.bytesRead;
        progress = response.progress;
        console.log(progress)
        setStep(`${progress}% Preparing to upload...`)
        setProgress(progress)

        completed = response.completed;
        firstFileName = response.fileName
        firstPath = response.path

      }




      const fileName = file.file.name.replace(/[^a-zA-Z0-9]/g, '');
      const cleanFileName = fileName.substring(0, 50);
      const sessionId = `SI${cleanFileName}${Math.floor(Math.random() * 100000)}`;

      const chunkSize = 5 * 1024 * 1024; // 5 MB
      const length = file.file.size;
      const chunkCount = Math.round(0.5 + (length / chunkSize));

      console.log(file)

      const val: ProcessingUploadModel = {
        dataLength: length,
        chunkCount: chunkCount,
        dataWrited: 0,
        startByte: 0,
        chunkIndex: 0,
        sessionId: sessionId,
        saveFileName: firstFileName,
        saveFilePath: firstPath,// Backend'de belirlenecek
        rootFileName: file.rootFileName !== null || file.rootFileName !== "" ? file.rootFileName : "",
        bucketKey: bucketKey
      }
      chunks.push(val)
      return val
    }

    else {
      return null;
    }


  };


  const processToChunksAsync = async (chunk: ProcessingUploadModel) => {
    setProcessToUpload(true)
    try {
      let iterationParameter: ProcessUploadIterationResponseModel = {
        startByte: chunk.startByte,
        response: undefined,
        progress: 0,
        dataWrited: 0
      }

      for (let i = 0; i < chunk.chunkCount; i++) {
        const service = new ProcessingUploadModelService()
        setSteppedFileName(chunk.saveFileName as string)
        if (i === chunk.chunkIndex) {
          console.log(chunk)
          iterationParameter = await service.PostAsync(chunk)
          console.log(iterationParameter)
        }
        else {
          const newChunk: ProcessingUploadModel = {
            startByte: iterationParameter.startByte,
            saveFileName: chunk.saveFileName,
            chunkIndex: chunk.chunkIndex,
            chunkCount: chunk.chunkCount,
            dataWrited: iterationParameter.dataWrited,
            sessionId: chunk.sessionId,
            saveFilePath: chunk.saveFilePath,
            bucketKey: chunk.bucketKey,
            dataLength: chunk.dataLength,
            rootFileName: chunk.rootFileName
          }
          console.log(newChunk)
          iterationParameter = await service.PostAsync(newChunk)
          console.log(iterationParameter)
        }
        setProgress(iterationParameter.progress)
        setStep(`${iterationParameter.progress}% Uploading...`)
        if (iterationParameter.progress === 100) {
          break;
        }
      }

      if (iterationParameter.progress === 100) {
        return iterationParameter
      }
    } catch (error: any) {
      console.error("Error:", error);
      setAfterUploadError(error.message !== undefined ? error.message : error.statusText)
      // break;
    }
  }

  const finalizeUploadAsync = async (list: ProcessUploadIterationResponseModel) => {
    setProcessToUpload(false)
    const finalizeService = new FinalizeUploadModelsService()
    const finalizeResponse = await finalizeService.PostAsync([list])
    return finalizeResponse
  }



  // Manifest kontrolünü yapan fonksiyon
  const checkManifestStatus = async (
    urns: string[],
    files: string[],
    uid: string,
  ) => {

    setStep("Translate starting...")
    setManifestStatus("Translate starting...")
    const manifestService = new CheckManifestUploadedObjectService();
    let isRequestInProgress = false; // İstek durumu flag'i
    let requestTimeoutId: NodeJS.Timeout | null = null; // İstek zaman aşımı için timeout ID'si
    const REQUEST_TIMEOUT = 60000; // 60 saniye
    const INTERVAL_DELAY = 10000; // 10 saniye

    const intervalId = setInterval(async () => {
      if (!urns || urns.length === 0 || !uid) {
        console.log("Gerekli veriler mevcut değil.");
        clearInterval(intervalId); // Gerekli veriler yoksa interval'i durdur
        return;
      }
      if (isRequestInProgress) {
        console.log("İstek zaten devam ediyor, bekleniyor...");
        return;
      }
      isRequestInProgress = true;
      requestTimeoutId = setTimeout(() => {
        console.log("İstek 1 dakikadan uzun sürdü, yeni istek yapılabilir.");
        isRequestInProgress = false;
      }, REQUEST_TIMEOUT);

      try {
        const response = await manifestService.GetByStringManyParamsAsync([urns[0], files[0], uid]);
        if (response.progress === "complete") {
          setProcessToUpload(false)
          setStep("Finishing...")
          setManifestStatus("Finishing...");
          //dispatch(resetCreateState())
          //setSelectedFiles([])
          setIsFinishedToLoading(true)
          clearInterval(intervalId); // Interval'i durdur
          if (requestTimeoutId) clearTimeout(requestTimeoutId);
        } else {
          setStep(response.progress);
          setManifestStatus(response.progress);
        }
        setIsProcessing(false)
        setWaitingForManifest(true)
      } catch (error: any) {
        console.error("Error:", error);
        setAfterUploadError(error.message !== undefined ? error.message : error.statusText)
      } finally {
        isRequestInProgress = false;

        if (requestTimeoutId) {
          clearTimeout(requestTimeoutId);
          requestTimeoutId = null;
        }
      }
    }, INTERVAL_DELAY);
  };

  // const processQueue = async (index: number) => {
  //   if (selectedFiles.length > 0) {
  //     setWaitingForManifest(false)
  //     setIsProcessing(true);
  //     console.log(index)
  //     console.log(selectedFiles)
  //     const currentFile = selectedFiles[index]

  //     console.log(currentFile)
  //     if (currentFile !== undefined) {
        
  //     //  setSteppedFileName(currentFile.file.name)
  //       const chunks = await getChunksAsync(currentFile);
  //       console.log(chunks)
  //       if (chunks !== null || chunks !== undefined) {
  //         var processChunkResponse = await processToChunksAsync(chunks as ProcessingUploadModel);
  //         console.log(processChunkResponse)
  //         const { urns, files, uid } = await finalizeUploadAsync(processChunkResponse as ProcessUploadIterationResponseModel);
  //         await checkManifestStatus(urns, files, uid);
  //         if (index < selectedFiles.length) {
  //           let newIndex = index + 1
  //           console.log(newIndex)
  //           setCurrentUploadIndex(newIndex)
  //           console.log(currentUploadIndex)
  //           processQueue(newIndex);
  //         }

  //         else{
  //           console.log("533.satir")
  //         }

       
  //       }
  //     }
  //   }
  //   else {
  //     setIsProcessing(false)
  //     setCurrentUploadIndex(0)
  //     return;
  //   }
  // };


  const processQueue = async (index: number) => {
    if (selectedFiles.length > 0) {
      setWaitingForManifest(false);
      setIsProcessing(true); 
      while (index < selectedFiles.length) {
        // console.log(`Döngüye girdi liste uzunluğu:${selectedFiles.length}`)
        // console.log(`Döngüye girdi index değeri:${index}`)
        const currentFile = selectedFiles[index];
        // console.log(`Index:${index}  Dosya adı:${currentFile}`);
        if (currentFile !== undefined) {
          const chunks = await getChunksAsync(currentFile);
          console.log(chunks);
          if (chunks !== null && chunks !== undefined) {
            var processChunkResponse = await processToChunksAsync(chunks as ProcessingUploadModel);
            console.log(processChunkResponse);
            const { urns, files, uid } = await finalizeUploadAsync(processChunkResponse as ProcessUploadIterationResponseModel);
            await checkManifestStatus(urns, files, uid);
          }
        }
        index++;
        // console.log(`Index artırıldı değeri:${index}`)
        // setCurrentUploadIndex(index);
        // console.log(`Current upload index değeri:${currentUploadIndex}`)
        // console.log(`Şu an ki dosya listesi uzunluğu:${selectedFiles.length}`)
      }
  
      setIsProcessing(false);
      setCurrentUploadIndex(0);
    }
  };
  

  useEffect(() => {
    dispatch(getUploadedObjectByUserId(jsonLoginInfo.uid))
  }, [dispatch, jsonLoginInfo.uid])

  const openObjectDetail = (urn: string) => {
    navigate('/object', {
      state: {
        urn
      },
    });
  };

  const getIconsOrThumbnail = (object: GetSubUploadedObjectResponseModel) => {
    const fileType = object.name.split(".")[1]
    if (object.thumbnail === "") {
      switch (fileType) {
        case "iam":
          return IAM

        case "ipt":
          return IPT

        case "dwfx":
          return DWFX

        case "dwg":
          return DWG

        case "fbx":
          return FBX

        case "zip":
          return ZIP

        case "rvt":
          return RVT

        case "dwf":
          return DWF

        case "sldasm":
          return SLDASM

        case "stl":
          return STL

        case "STL":
          return STL

        case "stp":
          return STP

        case "STP":
          return STP

        case "f3d":
          return F3D

        case "max":
          return MAX

        case "model":
          return MODEL

        case "glb":
          return GLB

        case "obj":
          return OBJ

        case "step":
          return STEP

        case "STEP":
          return STEP


        default:
          return ""

      }
    }
    else {
      return `data:image/jpeg;base64,${object.thumbnail}`
    }
  }
  return loginInfo && jsonLoginInfo.isAuthenticated ? (
    <>
      {objectListSelectorByUserId.loading ? (
        <div className='loading-bucket'>
          <LoadingScreen textColor='black' />
        </div>
      ) : (
        <div className='object-list p-0 h-full bg-[#f6f7f9]'>
          <div className='w-full px-12 py-2 bg-white border-b-2 border-gray-400'>
            <button
              className='object-list-upload-btn '
              onClick={() => {
                setUploadModal(true);
              }}
            >
              Upload
            </button>
          </div>
          <div className='layout-grid object-list-grid mx-[20px] '>
            {selectedSearchValue !== '' ? (
              bucketKey && selectedObjectByBucket.data ? (
                <CardItem
                  imageName={
                    selectedObjectByBucket.data.hasThumbnail
                      ? `data:image/jpeg;base64,${selectedObjectByBucket.data.thumbnail}`
                      : selectedObjectByBucket.data.objectType.name === 'iam'
                        ? `${IAM}`
                        : '' ||
                          selectedObjectByBucket.data.objectType.name === 'ipt'
                          ? IPT
                          : ''
                  }
                  title={selectedObjectByBucket.data.name}
                  text=''
                  buttonNames={[]}
                  buttonEvents={[
                    () => {
                      openObjectDetail(
                        selectedObjectByBucket.data?.urn as string
                      );
                    },
                  ]}
                  onDelete={() => {
                    setSelectedDeleteValue(
                      selectedObjectByBucket.data?.name as string
                    );
                    setConfirmModal(true);
                  }}
                  onClickToCard={() => { }}
                />
              ) : (
                <>
                  {selectedObject.data !== null ? (
                    <>
                      <>
                        <CardItem
                          imageName={
                            selectedObject.data.hasThumbnail
                              ? `data:image/jpeg;base64,${selectedObject.data.thumbnail}`
                              : ''
                          }
                          title={selectedObject.data.name}
                          text=''
                          buttonNames={['X', 'Y']}
                          buttonEvents={[
                            () => {
                              openObjectDetail(
                                selectedObject.data ? selectedObject.data.urn : ""
                              );
                            },
                            async () => {
                              const service =
                                new GetUploadedObjectHtmlContentService(
                                  'objects',
                                  'content'
                                );
                              const data =
                                selectedObject.data as GetUploadedObjectResponseModel;


                              const response =
                                await service.GetByStringParamAsync(data.urn);

                              if (response.content != null) {
                                setHtmlContent(response.content);
                                setTextAreaModal(true);
                              }
                            },
                          ]}
                          onDelete={() => { }}
                          onClickToCard={async () => {
                            const service =
                              new GetUploadedObjectModelViewService(
                                'objects',
                                'show'
                              );
                            const data =
                              selectedObject.data as GetUploadedObjectResponseModel;
                            const response =
                              await service.GetByStringParamAsync(data.urn);

                            if (response.url !== null) {
                              window.open(response.url);
                            }
                          }}
                        />
                      </>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )
            ) : (
              <>
                {objectListSelectorByUserId.data && objectListSelectorByUserId.data.length > 0 ? (
                  <>
                    {objectListSelectorByUserId.data.map((object) => (
                      <div className='object-card-wrapper' key={object.name}>
                        <CardItem
                          imageName={getIconsOrThumbnail(object)}
                          title={object.name}
                          text=''
                          buttonNames={['Detail', '</>']}
                          buttonEvents={[
                            () => openObjectDetail(object.urn),
                            async () => {
                              setHtmlContent(object.iframeButtonCSS)
                              setWebSite(object.webSite !== null || object.webSite !== undefined ? object.webSite : "")
                              setSelectedName(object.name)
                              setTextAreaModal(true);
                            }

                          ]}
                          onDelete={() => {
                            setSelectedDeleteValue(object.name);
                            setConfirmModal(true);
                          }}
                          onClickToCard={() => {
                            //openViewer(object.urn);
                            window.open(`${baseUrl}objects/content/${object.urn}/view/${jsonLoginInfo.uid}/properties/`)
                          }}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <Container
                    className="modal show p-0 !w-full"
                    style={{
                      display: 'block',
                      position: 'initial',
                      textAlign: 'center',
                    }}
                  >
                    <Modal.Dialog>
                      <Modal.Header style={{ justifyContent: 'center' }}>
                        <Modal.Title>
                          <FontAwesomeIcon icon={faFlushed} style={{ fontSize: '2rem', color: '#ff6f61' }} />
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body style={{ fontSize: '1.1rem', lineHeight: '1.5', color: '#555' }}>
                        <p>
                          There are no objects available. Please click the upload button or use the cloud upload method to proceed.
                        </p>
                      </Modal.Body>
                    </Modal.Dialog>
                  </Container>

                )}
              </>
            )}
          </div>

          {uploadModal && (
            <MultipleUploadModal
              setIsFinishedToLoading={setIsFinishedToLoading}
              setIsProcessing={setIsProcessing}
              isProcessing={isProcessing}
              setWaitingForManifest={setWaitingForManifest}
              waitingForManifest={waitingForManifest}
              manifestStatus={manifestStatus}
              step={step}
              steppedFileName={steppedFileName}
              currentUploadIndex={currentUploadIndex}
              handleFile={handleFileEvent}
              handleFileInput={handleFileInput}
              handleFileInputWithDrop={handleFileInputWithDrop}
              handleFileDrop={handleFileDrop}
              handleCloseModal={handleCloseModal}
              setIsDragDrop={setUploadModal}
              setSelectedFiles={setSelectedFiles}
              selectedFiles={selectedFiles}
              openZipOption={openZipOption}
              setOpenZipOption={setOpenZipOption}
              setZipFile={setZipFile}
              zipFile={zipFile}
              processZipFile={processZipFile}
              extractZipStatus={extractZipFileStatus}
              setExtractZipStatus={setExtractZipFileStatus}
              creditStatus={creditWarnings}
              setCreditStatus={setCreditWarnings}
              invalidFileMessage={invalidFileErrorMessage}
              setInvalidFileMessage={setInvalidFileErrorMessage}
              processQueue={processQueue}
              isFinishedToLoading={isFinishedToLoading}
              afterUploadError={afterUploadError}
              setAfterUploadError={setAfterUploadError}
              startToGetChunkStatus={startToGetChunkStatus}
              setStartToGetChunkStatus={setStartToGetChunkStatus}
              processToUpload={processToUpload}
              setProcessToUpload={setProcessToUpload}
            />
          )}

          {
            openSupportedFormats && (
              <Dialog
                open={openSupportedFormats}
                onClose={() => setOpenSupportedFormats(false)}
                aria-labelledby="file-format-dialog-title"
                maxWidth="md"
                fullWidth
              >
                <DialogTitle id="file-format-dialog-title">Supported 2D and 3D file formats</DialogTitle>
                <DialogContent>
                  <Typography variant="body2" gutterBottom>
                    Veewer can display the following file formats:
                  </Typography>
                  <Grid container spacing={2}>
                    {[0, 1, 2].map((column) => (
                      <Grid item xs={4} key={column}>
                        <List dense>
                          {supportedFormats.slice(column * 20, (column + 1) * 20).map((format) => (
                            <ListItem key={format}>
                              <ListItemText primary={format} />
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    ))}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenSupportedFormats(false)}>Close</Button>
                </DialogActions>
              </Dialog>
            )
          }

          {filterModal && <></>}

          {confirmModal && (
            <ConfirmModal
              word="delete the file"
              ifYes={deleteSelectedObject.loading}
              onYes={() => {
                dispatch(deleteUploadedObject({
                  bucketKey,
                  objectKey: selectedDeleteValue
                })).then((action) => {
                  if (action.meta.requestStatus === "fulfilled") {
                    setConfirmModal(false);
                    dispatch(updateWithDeleteObject({
                      bucketKey,
                      objectKey: selectedDeleteValue,
                    }));
                  }
                });
              }}
              onNo={async () => {
                setConfirmModal(false);
              }}
              show={confirmModal}
              onHide={setConfirmModal}
            />
          )}

          {textAreaModal && (
            <TextAreaModal
              show={textAreaModal}
              onHide={setTextAreaModal}
              incomingValue={htmlContent}
              secondOptionalValue={webSite}
              thirdOptionalValue={selectedName}
            />
          )}

          {
            openChangeModal && (
              <ChangeNameModal
                show={openChangeModal}
                onHide={() => setOpenChangeModal(false)}
                currentName={selectedName}
                objectId={selectedObjectId}
              />
            )
          }
        </div>
      )}
    </>
  ) : (
    <Unauthorized />
  );
};

export default ObjectList;


