import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { fetchAdminLogin, resetError } from '../store/features/admin/admin.login.slice';
import { RootState, useAppDispatch, useAppSelector } from '../store';
import LoadingScreen from '../utils/LoadingScreen';
import ELogo from "../assets/Veewer_black_stripes_E.png";
import '../styles/login/login.css';

export default function Login() {
  const navigate = useNavigate();
  const [userNameOrEmail, setUserNameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inputType, setInputType] = useState('text');
  const dispatch = useAppDispatch();
  const postAuthSelector = useAppSelector((state: RootState) => state.adminLoginSlice);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (postAuthSelector.data != null && postAuthSelector.loading === false) {
      const data = JSON.stringify({
        isAuthenticated: postAuthSelector.data.isAuthenticated,
        isSuperVisorAdmin: postAuthSelector.data.isSuperVisorAdmin,
      });
      sessionStorage.setItem('adminLogin', data);
      navigate('/');
    }
  }, [postAuthSelector.data, postAuthSelector.loading, navigate]);

  useEffect(() => {
    if (
      postAuthSelector.error !== null &&
      postAuthSelector.data === null &&
      postAuthSelector.loading === false
    ) {
      const error = postAuthSelector.error;
      Swal.fire('Login failed', error.error || error.message, 'error');
      dispatch(resetError());
    }
  }, [postAuthSelector.error, postAuthSelector.loading, dispatch]);

  return (
    <>
      {postAuthSelector.loading ? (
        <LoadingScreen textColor="black" tailwindcss='!text-sm !font-thin !font-extralight ' text="Attempting to log in to Weever by Admin" loadingTime="5s" />
      ) : (
        <div className='page-wrapper'>
        <div className='login-signup-container'>
        <div className="login-container">
          <div className="form">
            <div className="login-signup-header">
              <img src={ELogo} alt="sync3d-logo" width="45px" height="45px" />
              <h1 className="title">Admin Login</h1>
            </div>
            <form className='signup-form form'>
            <div className='group-flex'>
              <div className="form-group">
                <label htmlFor="userName">Username</label>
                <input
                  type={inputType}
                  value={userNameOrEmail}
                  onChange={(e) => setUserNameOrEmail(e.target.value)}
                  className="action-input"
                  autoComplete="username"
                />
              </div>    
              <div className="form-group" style={{ position: "relative" }}>
                <label htmlFor="password">Password</label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="action-input"
                />
                <span className="show-password" onClick={() => setShowPassword((prev) => !prev)}>
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </span>
              </div>
              </div>
              <div className='action-button-group-flex'>
              <button
                disabled={!userNameOrEmail || !password}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(fetchAdminLogin({ userName: userNameOrEmail, password }));
                }}
                className="action-button"
              >
                Login
              </button>
              </div>
            </form>
          </div>
        </div>
        </div>
        </div>
      )}
    </>
  );
}
