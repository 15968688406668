import React, { useState } from "react";

const WebhookCreator = () => {
  const [urn, setUrn] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  const createWebhook = async () => {
    if (!urn || !email) {
      setStatus("Lütfen URN ve e-posta adresini giriniz.");
      return;
    }

    try {
      const currentDate = new Date();
      const timestamp = `${currentDate.getFullYear()}${String(
        currentDate.getMonth() + 1
      ).padStart(2, "0")}${String(currentDate.getDate()).padStart(2, "0")}${String(
        currentDate.getHours()
      ).padStart(2, "0")}${String(currentDate.getMinutes()).padStart(2, "0")}${String(
        currentDate.getSeconds()
      ).padStart(2, "0")}`; // YYYYMMDDHHMMSS formatında zaman damgası

      const workflowId = `${email}_${timestamp}`; // Workflow ID oluştur

      // Webhook oluşturma isteği
      const webhookResponse = await fetch(
        "https://developer.api.autodesk.com/webhooks/v1/systems/derivative/events/extraction.finished/hooks",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer  eyJhbGciOiJSUzI1NiIsImtpZCI6IlhrUFpfSmhoXzlTYzNZS01oRERBZFBWeFowOF9SUzI1NiIsInBpLmF0bSI6ImFzc2MifQ.eyJzY29wZSI6WyJkYXRhOndyaXRlIiwiZGF0YTpyZWFkIiwiYnVja2V0OmNyZWF0ZSIsImJ1Y2tldDpkZWxldGUiLCJidWNrZXQ6cmVhZCIsInZpZXdhYmxlczpyZWFkIl0sImNsaWVudF9pZCI6Ijh4cjVUWVZwMk5pNHFQejd5T2RFTElFR045ZWtZYWtpIiwiaXNzIjoiaHR0cHM6Ly9kZXZlbG9wZXIuYXBpLmF1dG9kZXNrLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXV0b2Rlc2suY29tIiwianRpIjoiWXlraE1ONG40d3ZQVE9xWXdCdm9qdWpMN0hrV2Q3aVN0bGF4b04xOElMOWhQZG1xZG5IQU1UdjVyeU9RSlNSViIsImV4cCI6MTczMjI2MjE0NX0.S1yeyCCraYJHJZBLeVmjnSI3GKd1r1v2N8apB_JCTNAVI5yo-mh0uXDi4gl9s6Tq5qz-mVzO_VmZXIO9XjotEgKpWNMxtb_kRR5_qquBh1ZCFA76L71aUy4fePowpQT_mvODffQEHsyY68nq_QMfk7Kd6hWLRqzhBYAXTj1C0biijXnu1djCwpSO1toTk4iooXIP0LuPZx7XiGvChI_GudG4DlQBc6iyygshP43kBsX2D1WomamUlMtHEstrHgnCj0tRxNdvjOo90sqJpS1XRRjOCYuHuHldUdGANDaTd8DG2-wCm3FVatyeBSgjGFfu_iF0_zzYD63ogXvEwOkFIg", // Autodesk API Token buraya eklenmeli
          },
          body: JSON.stringify({
            callbackUrl: "https://veewerdev.azurewebsites.net/api/v1/webhook/call", // Yerel sunucu
            scope: {
              workflow: workflowId,
            },
          }),
        }
      );

      if (webhookResponse.ok) {
        setStatus("Webhook başarıyla oluşturuldu. İşlenmeye başlıyor...");

        // Model Derivative job gönderimi
        const jobResponse = await fetch(
          "https://developer.api.autodesk.com/modelderivative/v2/designdata/job",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IlhrUFpfSmhoXzlTYzNZS01oRERBZFBWeFowOF9SUzI1NiIsInBpLmF0bSI6ImFzc2MifQ.eyJzY29wZSI6WyJkYXRhOndyaXRlIiwiZGF0YTpyZWFkIiwiYnVja2V0OmNyZWF0ZSIsImJ1Y2tldDpkZWxldGUiLCJidWNrZXQ6cmVhZCIsInZpZXdhYmxlczpyZWFkIl0sImNsaWVudF9pZCI6Ijh4cjVUWVZwMk5pNHFQejd5T2RFTElFR045ZWtZYWtpIiwiaXNzIjoiaHR0cHM6Ly9kZXZlbG9wZXIuYXBpLmF1dG9kZXNrLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXV0b2Rlc2suY29tIiwianRpIjoiTjlNSHJ1N2pUQURpa1FmY2YyNWtqaWxlSnkzb1B3QURtTmRvV0JUYXF1YnhEOFRUdDFYSnlnT2FyMERxTmpBeiIsImV4cCI6MTczMjI2MjY4NiwidXNlcmlkIjoiTVlXVVBMMlQ1VENXIn0.e0XMvomWIZkIYgVqZ0KWTeI1xoNiQYKFq-q9cG0X3kO5ZLHvmvaAHf9ms2iyJp6fpsPesM85vJY1gDIAHbEKEsb_4kmY7CaSQRpaNqfhIC1sRDo4hfBHokdrk8VBVle5oZGKIyr0kxg6l3qllitXi0WqYcVK7-oRz2KP5DxtGsZ_FrWg0jnS1N-pQSQiCQs-oPb4cA0H7JMJPomVpR59h_XF8N91JzUklOpVWPHfPrh9AhyYkIS4p2rC9fvls3wyZOBpz-w-lEtbUWadkSeks7fFnwvnxnHDEV6cUBHpcaEG2jhysUlTx_JKce0-ol9qkYdGoH-rgW1TPbyzxrjoWw", // Autodesk API Token buraya eklenmeli
            },
            body: JSON.stringify({
              input: {
                urn: urn,
              },
              output: {
                formats: [
                  {
                    type: "obj", // Dönüştürme formatı
                  },
                ],
              },
              misc: {
                workflow: workflowId,
              },
            }),
          }
        );

        if (jobResponse.ok) {
          setStatus("Job gönderildi. Webhook'un tamamlanmasını bekliyoruz...");
        } else {
          setStatus("Job gönderilirken hata oluştu.");
        }
      } else {
        setStatus("Webhook oluşturulurken hata oluştu.");
      }
    } catch (error) {
      console.error("Hata oluştu:", error);
      setStatus("Bir hata meydana geldi.");
    }
  };

  return (
    <div>
      <h1>Webhook Creator</h1>
      <div>
        <label>
          URN:{" "}
          <input
            type="text"
            value={urn}
            onChange={(e) => setUrn(e.target.value)}
            placeholder="Dosya URN'sini giriniz"
          />
        </label>
      </div>
      <div>
        <label>
          E-posta:{" "}
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Kullanıcı e-posta adresini giriniz"
          />
        </label>
      </div>
      <button onClick={createWebhook}>Webhook Oluştur</button>
      <p>{status}</p>
    </div>
  );
};

export default WebhookCreator;
