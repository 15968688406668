// src/components/Modals/ChangeNameModal.tsx

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { resetUpdateState, updateUploadedObjectName } from '../../store/features/object/update.uploaded.object.name.slice';
import { useAppDispatch, useAppSelector } from '../../store';
import { UpdateUploadedObjectNameService } from '../../services/uploadedObject/update.uploadedObject.name.service';
import CircleLoading from '../Loading/CircleLoading';

interface ChangeNameModalProps {
  show: boolean;
  onHide: () => void;
  currentName: string;
  objectId: string;
}

const ChangeNameModal: React.FC<ChangeNameModalProps> = ({ show, onHide, currentName, objectId }) => {
  const [newName, setNewName] = useState(currentName);
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  const updateNameSelector=useAppSelector(x=>x.updateUploadedObjectNameSlice)
  const [updateLoading, setUpdateLoading]=useState(false)

  useEffect(() => {
    setNewName(currentName);
    setError('');
  }, [currentName]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };

  const handleUpdateDispatch=async(id:string,newName:string)=>{
    console.log(id)
    console.log(newName)
    setUpdateLoading(true)
    const service=new UpdateUploadedObjectNameService()
     service.PostAsync({
      id,
      name: newName
    }).then((data)=>{
      console.log(data)
      setUpdateLoading(false)
      if(data.isSuccess){
        setError('')
        onHide()
      }
      else{
        setError('Modal name can not be changed')
      }
    })
        
  }

  const handleUpdate = () => {
    if (newName.trim() === '') {
      setError('Name cannot be empty.');
      return;
    }
    else{
      handleUpdateDispatch(objectId, newName)
    }
    
    
  };

  return (

    <Dialog open={show} onClose={onHide} aria-labelledby="change-name-dialog-title" maxWidth="sm" fullWidth>
      
      <DialogTitle id="change-name-dialog-title">Change Model Name</DialogTitle>
      {
          updateLoading?(
            <DialogContent>
              <CircleLoading text='Change to model name...' />
          </DialogContent>
          ):(
            <>
            <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Model Name"
              type="text"
              fullWidth
              value={newName}
              onChange={handleChange}
              error={!!error}
              helperText={error}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onHide} color="secondary">
              Cancel
            </Button>
            <Button onClick={()=>{
              handleUpdate();
            }} color="primary" variant="contained">
              Update
            </Button>
          </DialogActions>
          </>
          )
      }
   
    </Dialog>
  );
};

export default ChangeNameModal;
