import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetUploadedObjectRequest } from "../../../models/object/uploadedObject/get.uploadedObject.request.model";
import { GetUploadedObjectResponseModel } from "../../../models/object/uploadedObject/get.uploadedObject.response.model";
import { GetUploadedObjectService } from "../../../services/uploadedObject/get.uploadedObject.service";


interface GetUploadedObjectState extends BaseState<GetUploadedObjectResponseModel>{

}


let initialState:GetUploadedObjectState={
    data:null,
    error:null,
    loading:false
}





export const getUploadedObject=createAsyncThunk("uploadedObject/get",async(name:string)=>{

    const getUploadedObjectService=new GetUploadedObjectService("objects","")
    const response=await getUploadedObjectService.GetByStringParamAsync(name)

    return response
})


export const getUploadedObjectByBucketKeyAndName=createAsyncThunk("uploadedObject/get/name/bucketKey",
async(request:GetUploadedObjectRequest)=>{
    const getUploadedObjectService=new GetUploadedObjectService("objects","")
    const response=await getUploadedObjectService.GetByStringManyParamsAsync([request.bucketKey,request.name])
    return response
})



export const getUploadedObjectSlice=createSlice({
    name:"getUploadedObjectSlice",
    initialState,
    reducers:{
        removeDefaultValue:(state,action)=>{
            state.data=null
        }
    },
    extraReducers(builder) {
        
        builder
        .addCase(getUploadedObject.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(getUploadedObject.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(getUploadedObject.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})


export const getUploadedObjectByBucketAndNameSlice=createSlice({
    name:"getUploadedObjectSlice",
    initialState,
    reducers:{
        removeDefaultValue:(state,action)=>{
            state.data=null
        }
    },
    extraReducers(builder) {
        
        builder
        .addCase(getUploadedObjectByBucketKeyAndName.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(getUploadedObjectByBucketKeyAndName.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(getUploadedObjectByBucketKeyAndName.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    }
})

export default {
    getUploadedObjectAction:getUploadedObjectSlice.actions.removeDefaultValue,
    getUploadedObjectByBucketAndNameAction:getUploadedObjectByBucketAndNameSlice.actions.removeDefaultValue, 
    getUploadedObject:getUploadedObjectSlice.reducer,
    getUploadedObjectByBucketAndName:getUploadedObjectByBucketAndNameSlice.reducer
} 
