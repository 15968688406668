import { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../store';
import { getSubscriptions } from '../store/features/subscription/get.all.subscription.infos.slice';

export default function Component() {

  const dispatch = useAppDispatch();
  const subscriptionSlice = useAppSelector((x: RootState) => x.getSubscriptionsSlice)
  const subscriptions = subscriptionSlice.data
  const [isYearly, setIsYearly] = useState(false);

  useEffect(() => {
    dispatch(getSubscriptions())
  }, [dispatch])

  const toggleBillingCycle = () => {
    setIsYearly(!isYearly);
  };

  return (
    <div className="min-h-full py-12 px-4 sm:px-6 lg:px-8">
    <div className="max-w-[90%] mx-auto">
      <div className="text-center space-y-4 mb-12">
        <h1 className="text-3xl font-bold text-gray-900 sm:text-4xl lg:text-5xl">
          Simple, transparent pricing
        </h1>
        <p className="text-lg text-gray-600 sm:text-xl">
          Start with our <span className="text-primary font-semibold">7-day free trial</span>
        </p>
        <p className="text-xs text-gray-500 sm:text-sm">We've built this page using our own service 💚</p>
        {/* Billing cycle toggle */}
        <div className="flex items-center justify-center mt-6">
        <span className={`text-sm font-medium mr-2 ${!isYearly ? 'text-black' : 'text-gray-400'}`}>Monthly</span>
        <button
          onClick={toggleBillingCycle}
          className="relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          style={{ backgroundColor: isYearly ? '#4F46E5' : '#D1D5DB' }}
        >
          <span
            className={`${
              isYearly ? 'translate-x-6' : 'translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </button>
        <span className={`text-sm font-medium ml-2 ${isYearly ? 'text-black' : 'text-gray-400'}`}>Yearly</span>
      </div>
      </div>
      <div className="grid gap-4 sm:gap-6 lg:gap-12 2xl:grid-cols-5 lg:grid-cols-2 sm:grid-cols-1 xl:grid-cols-3 mt-8 sm:mt-16">
        { subscriptions && subscriptions.map((plan) => (
          <div
            key={plan.name}
            className={`relative flex flex-col rounded-2xl shadow-lg overflow-hidden ${
              plan.isMostPopular
                ? 'bg-gradient-to-b from-gray-800 to-gray-900 text-white scale-105 sm:scale-100 lg:scale-105 z-10'
                : 'bg-white'
            }`}
          >
            {plan.isMostPopular && (
              <>
                <div className="absolute top-0 left-0 right-0 h-1 bg-yellow-400" />
                <div className="absolute top-0 left-1/2 -translate-x-1/2 px-4 py-1 bg-yellow-400 text-gray-900 text-xs font-semibold rounded-b-lg">
                  Most Popular
                </div>
              </>
            )}
            
            <div className="p-6 flex-grow space-y-4 sm:space-y-6">
              <div className="space-y-1 sm:space-y-2">
                <h3 className={`text-base sm:text-lg font-semibold ${plan.isMostPopular ? 'text-gray-100' : 'text-gray-900'}`}>
                  {plan.name}
                </h3>
                <div className="flex items-baseline">
                  <span className={`text-3xl sm:text-4xl font-bold tracking-tight ${plan.isMostPopular ? 'text-white' : 'text-gray-900'}`}>
                  {plan.name !== "Enterprise" && plan.name!=="Free" ? "$" : ""}{isYearly ? plan.pricing.yearly : plan.pricing.monthly}
                  </span>
                  {plan.pricing.monthly !== 'Custom' && (
                    <span className={`ml-1 text-xs sm:text-sm ${plan.isMostPopular ? 'text-gray-300' : 'text-gray-500'}`}>
                      /{isYearly ? 'year' : 'month'}
                    </span>
                  )}
                </div>
              </div>
              <ul className="space-y-2 sm:space-y-4">
                {plan.features.map((feature, i) => (
                  <li key={i} className="flex items-start gap-2 sm:gap-3">
                    <div className="flex-shrink-0 h-5 w-5 sm:h-6 sm:w-6">
                      {feature.available? (
                        <svg className={`h-5 w-5 ${plan.isMostPopular ? 'text-yellow-400' : 'text-green-500'}`} viewBox="0 0 24 24" fill="currentColor">
                          <path d="M10 15.172l-3.59-3.59-1.415 1.415L10 18 20 8l-1.415-1.415z" />
                        </svg>
                      ) : (
                        <svg className="h-5 w-5 text-red-500" viewBox="0 0 24 24" fill="currentColor">
                          <path d="M18.3 5.71L12 12.001l-6.29-6.29-1.42 1.42 6.29 6.29-6.29 6.29 1.42 1.42L12 14.421l6.29 6.29 1.42-1.42-6.29-6.29 6.29-6.29z" />
                        </svg>
                      )}
                    </div>
                    <span className={`text-xs sm:text-sm ${plan.isMostPopular ? 'text-gray-300' : 'text-gray-600'}`}>
                      {feature.featureName}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className={`p-4 sm:p-6 ${plan.isMostPopular ? 'bg-gray-700' : 'bg-gray-50'}`}>
              <button
                className={`w-full py-2 sm:py-3 px-4 rounded-xl text-sm font-semibold transition-colors
                  ${
                    plan.isMostPopular
                      ? 'bg-yellow-400 text-gray-900 hover:bg-yellow-300'
                      : 'bg-gray-900 text-white hover:bg-gray-800'
                  }
                `}
              >
                 {plan.pricing.monthly === "Custom" && plan.pricing.yearly === "Custom" ? "Contact Us" : "Get Started"}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  )
}

