import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { fetchUserRegister, reset } from '../../store/features/user/user.register.slice';
import Swal from 'sweetalert2';
import CircleLoading from '../Loading/CircleLoading';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import LoadingScreen from '../../utils/LoadingScreen';

const SignUp = ({setActiveAction}:any) => {
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberValidations, setPhoneNumberValidations] = useState({
    validLength: false,
    startsWithPlus: false,
    validFormat: false
  });

  const dispatch = useAppDispatch();
  const postRegisterSelector = useAppSelector((x) => x.fetchUserRegisterSlice);
  const loginSelector = useAppSelector((x) => x.postAuth);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    phoneNumber: '',
    password: ''
})

  const passwordRules = [
    {
      text: 'Minimum 6 characters',
      state: /.{6,}/.test(password),
      errorMessage:'Password must be at least 6 characters'
  },
  {
      text: 'A non-alphanumeric character',
      state: /.*[^a-zA-Z\d].*/.test(password),
      errorMessage:'Password must be at least 1 non-alphanumeric character'
  },
  {
      text: 'A lowercase letter',
      state: /[a-z]/.test(password),
      errorMessage:'Password must contain at least 1 lowercase letter'
  },
  {
      text: 'An uppercase letter',
      state: /[A-Z]/.test(password),
      errorMessage:'Password must contain at least 1 uppercase letter'
  },
  {
      text: 'A number',
      state: /\d/.test(password),
      errorMessage:'Password must contain at least 1 number'
  },
  ];

  const phoneRules = [
    {
      text: 'Valid length (min 10 characters)',
      state: phoneNumberValidations.validLength,
    },
    {
      text: 'Starts with +',
      state: phoneNumberValidations.startsWithPlus,
    },
    {
      text: 'Valid format',
      state: phoneNumberValidations.validFormat,
    },
  ];

  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const phoneRegex = /^\+?[1-9]\d{1,14}$/;
  //const nameRegex = /^[a-zA-Z0-9\s]+$/;

  //const nameRegex = /^[a-zA-Z0-9\s]*$/;
  const nameRegex = /^[a-zA-Z0-9\sÇŞĞÜÖİçşğüöı]*$/;



  const validateForm = () => {
    let formErrors = { firstName: '', lastName: '', userName: '', email: '', phoneNumber: '', password: '' };

    if (!firstName.trim()) {
      formErrors.firstName = 'First name is required';
    } else if (!nameRegex.test(firstName)) {
      formErrors.firstName = 'First name contains invalid characters or is too short';
    }else if (firstName.length<3){
      formErrors.firstName = 'First name is too short';
    } else if (/^\d+$/.test(firstName)) {
       formErrors.userName="First Name cannot consist of only numbers.";
  }

    if (!lastName.trim()) {
      formErrors.lastName = 'Last name is required';
    } else if (!nameRegex.test(lastName)) {
      formErrors.lastName = 'Last name contains invalid characters';
    }
    else if(lastName.length<3){
      formErrors.firstName = 'Last name is too short';
    }
    else if (/^\d+$/.test(lastName)) {
      formErrors.userName= "Last Name cannot consist of only numbers.";
    }

    if (!userName.trim()) {
      formErrors.userName = 'User name is required';
    } else if (!nameRegex.test(userName)) {
      formErrors.userName = 'User name contains invalid characters';
    }
    else if(userName.length<3){
      formErrors.userName = 'User name is too short';
    }else if (/^\d+$/.test(userName)) {
      formErrors.userName="User name cannot consist of only numbers.";
    }

    if (!isValidEmail) {
      formErrors.email = 'Invalid email format';
    }

    passwordRules.forEach((passwordRule) => {
      if (!passwordRule.state) {
        formErrors.password = passwordRule.errorMessage;
      }
    });

    setErrors(formErrors);
    return Object.values(formErrors).every(error => !error);
    };

  const handlePhoneChange = (phone:string) => {
    setPhoneNumber(`+${phone}`);
    //validateForm()
    setPhoneNumberValidations({
      validLength: phoneNumber.length >=  10,
      startsWithPlus: phoneNumber.startsWith('+'), // Güncellendi
      validFormat: phoneRegex.test(phoneNumber)
    });
  };

  useEffect(() => {
    if (postRegisterSelector.data != null && postRegisterSelector.loading === false) {
      Swal.fire({
        title: "Your registration is almost complete!",
        text: "Welcome to Veewer! We're thrilled to have you onboard. Please check your email to confirm your email address. Once confirmed, you can log in and explore all the features we have to offer.",
        icon: "success",
        showCancelButton: true,
        // confirmButtonText: "Verify Your Email",
        cancelButtonText: "Close",
        // confirmButtonColor: "#007bff",
        cancelButtonColor: "#6c757d",
      }).then((result) => {
        setActiveAction("login");
        // if (result.isConfirmed) {
        //   navigate('/verifyPhone',{
        //     state:{userId:postRegisterSelector.data!==null ? postRegisterSelector.data.userId:""}
        //   });
         
        // }
      });
      dispatch(reset());
    }
  }, [postRegisterSelector.data, postRegisterSelector.loading]);

  useEffect(() => {
    if (
      postRegisterSelector.error !== null &&
      postRegisterSelector.data === null &&
      postRegisterSelector.loading === false
    ) {
      const error = postRegisterSelector.error;
      if (error.error !== undefined || error.error !== null) {
        Swal.fire('Register failed', error.error, 'error');
        dispatch(reset());
      } else {
        Swal.fire('Register failed', error.message, 'error');
        dispatch(reset());
      }
    }
  }, [
    postRegisterSelector.data,
    postRegisterSelector.error,
    postRegisterSelector.loading,
  ]);

  return (
    <>
      {postRegisterSelector.loading ? (
        <CircleLoading text='Trying to create user...' />
      ) : (
        <>
          {loginSelector.loading ? (
            <LoadingScreen textColor='black' />
          ) : (
            <form className='signup-form form'>
              <div className='group-flex'>
                <div className='form-group'>
                  User Name*
                  <label htmlFor='username'></label>
                  <input
                    required
                    className={`action-input ${errors.userName? 'is-invalid' : ''} `}
                    type='text'
                    value={userName}
                    onChange={(e) => {
                     // validateForm()
                      setUserName(e.target.value);
                    }}
                    onBlur={()=>{
                      validateForm()
                    }}
                  />
                   {errors.userName!=="" && <div className="invalid-feedback">{errors.userName}</div>}
                </div>

                <div className='form-group'>
                  First Name*
                  <label htmlFor='firstname'></label>
                  <input
                    required
                    className={`action-input ${errors.firstName? 'is-invalid' : ''} `}
                    type='text'
                    value={firstName}
                    onChange={(e) => {
                     // validateForm()
                      setFirstName(e.target.value);
                    }}
                    onBlur={()=>{
                      validateForm()
                    }}
                  />
                   {errors.firstName!=="" && <div className="invalid-feedback">{errors.firstName}</div>}
                </div>

                <div className='form-group'>
                  Last Name*
                  <label htmlFor='lastname'></label>
                  <input
                    required
                    className={`action-input ${errors.lastName ? 'is-invalid' : ''} `}
                    type='text'
                    value={lastName}
                    onChange={(e) => {
                      //validateForm()
                      setLastName(e.target.value);
                    }}
                    onBlur={()=>{
                      validateForm()
                    }}
                    
                  />
                   {errors.lastName!=="" && <div className="invalid-feedback">{errors.lastName}</div>}
                </div>

                <div className='form-group'>
                  Company*
                  <label htmlFor='companyname'></label>
                  <input
                    required
                    className='action-input'
                    type='text'
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                    
                  />
                </div>

                <div className='form-group'>
                  Email*
                  <label htmlFor='email'></label>
                  <input
                    required
                    className={`action-input ${errors.email ? 'is-invalid' : ''}`}
                    type='email'
                    value={email}
                    onChange={(e) => {
                      //validateForm()
                      setEmail(e.target.value);
                    }}
                    onBlur={()=>{
                      validateForm()
                    }}
                  />
                    {errors.email!=="" && <div className="invalid-feedback">{errors.email}</div>}
                </div>

                {/* <div className='form-group'>
                  Phone Number*
                  <label htmlFor='phonenumber'></label>
                  <PhoneInput
                    country={undefined}
                    value={`+${phoneNumber}`}
                    onChange={handlePhoneChange}
                    onBlur={()=>{
                      validateForm()
                    }}
                    inputStyle={{ width: '100%' }} // Güncellendi
                    isValid={() => phoneNumberValidations.validFormat}
                  />
                  {errors.phoneNumber!=="" && <div className="invalid-feedback">{errors.phoneNumber}</div>}
                  <ul className='phone-rule-list list-none p-0'>
                    {phoneRules.map((rule, index) => (
                      <li
                        className='rule mt-2'
                        key={index}
                      >
                        <span
                          style={{
                            color: rule.state ? 'green' : 'red',
                            marginRight: '0.3rem',
                          }}
                        >
                          {rule.state ? '✔' : '•'}
                        </span>
                        {rule.text}
                      </li>
                    ))}
                  </ul>
                </div> */}

                <div className='form-group'>
                  <label htmlFor='password'>Password</label>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    required
                    className='action-input'
                    value={password}
                    onChange={(e) => {
                      //validateForm()
                      setPassword(e.target.value);
                    }}
                    onBlur={()=>{
                      validateForm()
                    }}
                  />
                  <ul className='password-rule-list'>
                    {passwordRules.map((rule, index) => (
                      <li
                        className='rule'
                        key={index}
                      >
                        <span
                          style={{
                            color: rule.state ? 'green' : 'red',
                            marginRight: '0.3rem',
                          }}
                        >
                          {rule.state ? '✔' : '•'}
                        </span>
                        {rule.text}
                      </li>
                    ))}
                  </ul>
                  <span
                    className='show-password'
                    onClick={() => setShowPassword((p) => !p)}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </span>
                </div>
              </div>
              <button
                disabled={
                  !userName ||
                  !password ||
                  !firstName ||
                  !lastName ||
                  !companyName ||
                  !isValidEmail ||
                  postRegisterSelector.loading ||
                  loginSelector.loading ||
                  Object.values(errors).filter(err => err!=="").length>0
                }
                className='action-button direct-signup'
                onClick={() => {
                  dispatch(
                    fetchUserRegister({
                      userName: userName,
                      firstName: firstName,
                      lastName: lastName,
                      companyName: companyName,
                      password: password,
                      email: email,
                      phoneNumber: "",
                      subscriptionName: "Free"
                    })
                  );
                }}
              >
                Sign up
              </button>
            </form>
          )}
        </>
      )}
    </>
  );
};

export default SignUp;

