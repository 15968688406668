import VeewerLogo from '../assets/Veewer siyah @4x.png';

export default function Hero({ scrollToBeta }: { scrollToBeta: () => void }) {
 
  return (
    <header className="flex flex-col items-center py-16 text-center  h-screen justify-center">
      <div className="flex flex-col items-center space-y-24 max-w-screen-2xl tracking-widest">
        <h1 className="font-extralight text-[clamp(2rem,5vw,5rem)] px-3"> 
        <span className="relative inline-block">
            <span className="relative"><b>Get people talking about</b></span>
          </span>{' '}
          <span className="relative inline-block">
            <span className="relative"><b>your product with</b></span>
          </span>{' '}
          <div className="flex items-center justify-center w-full mt-4">
            <img
              src={VeewerLogo}
              alt="Veewer Logo" 
              className="w-[clamp(10rem,25vw,35rem)]"
            />
          </div>
        </h1>
        
        <button id='join-beta' onClick={scrollToBeta} className="px-8 py-3 sm:text-sm hover:!bg-white  2xl:text-lg font-semibold border border-black hover:!text-black text-white bg-black rounded-full transition-colors duration-300">
          Join Beta Program
        </button>
      </div>
    </header>
  );
}
