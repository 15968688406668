// import React, { useEffect, useState } from 'react';
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   TablePagination,
//   Typography,
//   Box,
// } from '@mui/material';
// import { useLocation, useNavigate } from 'react-router-dom';
// import CircleLoading from '../../components/Loading/CircleLoading';
// import { GetUploadedObjectMetadataService } from '../../services/uploadedObject/get.uploaded.object.metadata.service';
// import { GetUploadedObjectMetadataResponseModel } from '../../models/object/uploadedObject/get.uploadedObject.response.model';

// const MetadataTable = ({ metadata }: { metadata: GetUploadedObjectMetadataResponseModel[] }) => {
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);

//   const handleChangePage = (event: unknown, newPage: number) => setPage(newPage);

//   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const renderPropertyValue = (value: any): string => {
//     if (typeof value === 'object' && value !== null) {
//       return JSON.stringify(value);
//     }
//     return String(value);
//   };

//   return (
//     <Paper sx={{ width: '100%', overflow: 'hidden' }}>
//       <TableContainer sx={{ maxHeight: 440 }}>
//         <Table stickyHeader aria-label="metadata table">
//           <TableHead>
//             <TableRow>
//               <TableCell>Name</TableCell>
//               <TableCell>Properties</TableCell>
//               <TableCell>Object ID</TableCell>
//               <TableCell>External ID</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {metadata.map((item, index) => (
//               <TableRow hover role="checkbox" tabIndex={-1} key={index}>
//                 <TableCell>{item.properties.Name}</TableCell>
//                 <TableCell>
//                   {Object.entries(item.properties).map(([key, value]) => (
//                     <Box key={key} mb={1}>
//                       <Typography variant="body2" component="span" fontWeight="bold">
//                         {key}:
//                       </Typography>{' '}
//                       <Typography variant="body2" component="span">
//                         {renderPropertyValue(value)}
//                       </Typography>
//                     </Box>
//                   ))}
//                 </TableCell>
//                 <TableCell>{item.objectId}</TableCell>
//                 <TableCell>
//                   {item.externalId.length > 20 ? item.externalId.substring(0, 20) : item.externalId}
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <TablePagination
//         rowsPerPageOptions={[10, 25, 100]}
//         component="div"
//         count={metadata.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />
//     </Paper>
//   );
// };

// const ObjectDetail = () => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   const urn = location.state.urn as string;
//   const bucketName = sessionStorage.getItem('b') as string;

//   const [object, setObject] = useState({
//     metadata: [] as GetUploadedObjectMetadataResponseModel[],
//   });
//   const [loading, setLoading] = useState(true);

//   const handleBackClick = () => navigate('/models');

//   useEffect(() => {
//     document.body.style.overflow = loading ? 'hidden' : 'auto';

//     const fetchMetadata = async () => {
//       setLoading(true);
//       const getUploadedObjectMetadataService = new GetUploadedObjectMetadataService(
//         'objects',
//         'getModelDetail'
//       );
//       const response = await getUploadedObjectMetadataService.GetByStringManyParamsAsync([
//         urn,
//         bucketName,
//       ]);
//       setObject({ metadata: response.metadata });
//       setLoading(false);
//     };

//     fetchMetadata();

//     return () => {
//       document.body.style.overflow = 'auto';
//     };
//   }, [urn, bucketName, loading]);

//   if (loading) {
//     return (
//       <div className="w-full h-screen flex items-center justify-center">
//         <CircleLoading text="Getting detail information..." />
//       </div>
//     );
//   }

//   return (
//     <Box sx={{ width: '100%', maxWidth: 1200, margin: 'auto', padding: 2 }}>
//       <Typography variant="h4" gutterBottom>
//         Model Detail Information
//       </Typography>
//       {object.metadata.length > 0 ? (
//         <MetadataTable metadata={object.metadata} />
//       ) : (
//         <Typography variant="h6" color="textSecondary" textAlign="center" marginTop={4}>
//           No metadata found for this object.
//         </Typography>
//       )}
//     </Box>
//   );
// };

// export default ObjectDetail;


import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  Box,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CircleLoading from '../../components/Loading/CircleLoading';
import { GetUploadedObjectMetadataService } from '../../services/uploadedObject/get.uploaded.object.metadata.service';
import { GetUploadedObjectMetadataResponseModel } from '../../models/object/uploadedObject/get.uploadedObject.response.model';

const MetadataTable = ({ metadata }: { metadata: GetUploadedObjectMetadataResponseModel[] }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderPropertyValue = (value: any): string => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return String(value);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="metadata table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Properties</TableCell>
              <TableCell>Object ID</TableCell>
              <TableCell>External ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {metadata.map((item, index) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                <TableCell>{item.properties.Name}</TableCell>
                <TableCell>
                  {Object.entries(item.properties).map(([key, value]) => (
                    <Box key={key} mb={1}>
                      <Typography variant="body2" component="span" fontWeight="bold">
                        {key}:
                      </Typography>{' '}
                      <Typography variant="body2" component="span">
                        {renderPropertyValue(value)}
                      </Typography>
                    </Box>
                  ))}
                </TableCell>
                <TableCell>{item.objectId}</TableCell>
                <TableCell>
                  {item.externalId.length > 20 ? item.externalId.substring(0, 20) : item.externalId}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={metadata.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

const ObjectDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const urn = location.state.urn as string;
  const bucketName = sessionStorage.getItem('b') as string;

  const [object, setObject] = useState({
    metadata: [] as GetUploadedObjectMetadataResponseModel[],
  });
  const [loading, setLoading] = useState(true);

  const handleBackClick = () => navigate('/models');

  useEffect(() => {
    const fetchMetadata = async () => {
      setLoading(true);
      const getUploadedObjectMetadataService = new GetUploadedObjectMetadataService(
        'objects',
        'getModelDetail'
      );
      try {
        const response = await getUploadedObjectMetadataService.GetByStringManyParamsAsync([
          urn,
          bucketName,
        ]);
        setObject({ metadata: response.metadata });
      } catch (error) {
        console.error('Error fetching metadata:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMetadata();
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [urn, bucketName]);

  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <CircleLoading text="Getting detail information..." />
      </div>
    );
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 1200, margin: 'auto', padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Model Detail Information
      </Typography>
      {object.metadata.length > 0 ? (
        <MetadataTable metadata={object.metadata} />
      ) : (
        <Typography variant="h6" color="textSecondary" textAlign="center" marginTop={4}>
          No metadata found for this object.
        </Typography>
      )}
    </Box>
  );
};

export default ObjectDetail;
