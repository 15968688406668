import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetUserCountsWeeklyModel } from "../../../models/admin/get.user.counts.model";
import { GetUserCountsWeeklyService } from "../../../services/admin/get.user.counts.weekly.service";


interface GetUserCountsWeeklyState extends BaseState<GetUserCountsWeeklyModel[]>{

}

let initialState:GetUserCountsWeeklyState={
    loading: false,
    data: null,
    error: null
}


export const getUserCountsWeekly=createAsyncThunk("fetch/getUserCountsWeekly", async()=>{
    const service=new GetUserCountsWeeklyService()
    const response=await service.GetAsync()
    return response
})


export const getUserCountsWeeklySlice=createSlice({
    name:"getUserCountsWeeklySlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(getUserCountsWeekly.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(getUserCountsWeekly.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(getUserCountsWeekly.rejected, (state, action: any) => {
          state.loading = false;
          state.error = action.payload;
        });
    }
})

export default getUserCountsWeeklySlice.reducer;