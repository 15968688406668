import { PutUploadedObjectRequestModel } from "../../../models/object/uploadedObject/put.uploadedObject.request.model";
import { ProcessingUploadModel } from "../../../models/object/uploadedObject/uploadV2/processing.upload.model";
import { BasePutService } from "../../base/base.put.service";



export class StartingUploadModelsService extends BasePutService<PutUploadedObjectRequestModel,ProcessingUploadModel[]>
{
    constructor(controllerName:string="objects",endpointName:string="startToUpload"){
        super(controllerName, endpointName)
    }

    async StartToUploadingAsync(request: PutUploadedObjectRequestModel) {
        const formData = new FormData();
        formData.append("bucketKey", request.bucketName);

        request.files.forEach((fileObj, index) => {
            formData.append(`files[${index}].file`, fileObj.file); // Dosya olarak eklenir
            formData.append(`files[${index}].rootFileName`, fileObj.rootFileName || ''); // rootFileName string olarak eklenir
        });

        return await this.PutWithFormDataAsync(formData);
    }
}