'use client'

import React, { useRef, useEffect, useState } from 'react'

export default function LoadingScreen({ textColor = 'black', text = "VEEWER", tailwindcss = "", loadingTime = "3s", isLoginMobile = false }) {
  const textRef = useRef(null);
  const [textWidth, setTextWidth] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      setTextWidth(textRef.current.offsetWidth);
    }
  }, []);

  if (isLoginMobile) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <div className="flex items-center space-x-4">
          <div className="relative w-12 h-12">
            <div className="absolute inset-0 border-t-2 border-r-2 border-b-2 border-transparent rounded-full animate-spin"
                 style={{ borderTopColor: textColor, borderRightColor: textColor, animationDuration: loadingTime }}></div>
          </div>
          <div className={`text-2xl font-bold ${tailwindcss}`} style={{ color: textColor }}>
            {text}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex justify-center items-center h-full w-full">
      <div className="relative text-center">
        <div
          ref={textRef}
          className={`text-7xl font-bold tracking-[0.5em] ${tailwindcss} `}
          style={{
            color: 'transparent',
            WebkitTextStroke: `2px ${textColor}`,
          }}
        >
          {text}
        </div>
        <div
          className={`absolute top-0 left-0 overflow-hidden whitespace-nowrap text-7xl font-bold tracking-[0.5em] ${tailwindcss}`}
          style={{
            color: textColor,
            WebkitTextStroke: `2px ${textColor}`,
            animation: `fillAnimation ${loadingTime} linear infinite`,
          }}
        >
          {text}
        </div>
        <div
          className="absolute top-0 left-0 h-full"
          style={{
            width: '3px',
            backgroundColor: textColor,
            animation: `lineAnimation ${loadingTime} linear infinite`,
          }}
        />
      </div>
      <style jsx>{`
        @keyframes fillAnimation {
          0%, 100% {
            width: 0;
          }
          90% {
            width: 100%;
          }
        }

        @keyframes lineAnimation {
          0% {
            transform: translateX(0);
          }
          90% {
            transform: translateX(${textWidth}px);
          }
        }
      `}</style>
    </div>
  )
}

