import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { GetUserCountsMonthlyModel } from "../../../models/admin/get.user.counts.model";
import { GetUserCountsMonthlyService } from "../../../services/admin/get.user.counts.montly.service";


interface GetUserCountsMonthlyState extends BaseState<GetUserCountsMonthlyModel[]>{

}

let initialState:GetUserCountsMonthlyState={
    loading: false,
    data: null,
    error: null
}


export const getUserCountsMonthly=createAsyncThunk("fetch/getUserCountsMonthly", async()=>{
    const service=new GetUserCountsMonthlyService()
    const response=await service.GetAsync()
    return response
})


export const getUserCountsMonthlySlice=createSlice({
    name:"getUserCountsMonthlySlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(getUserCountsMonthly.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(getUserCountsMonthly.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(getUserCountsMonthly.rejected, (state, action: any) => {
          state.loading = false;
          state.error = action.payload;
        });
    }
})

export default getUserCountsMonthlySlice.reducer;