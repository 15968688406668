import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { SecondaryGetUploadedObjectRequest } from "../../../models/object/uploadedObject/get.uploadedObject.request.model";
import { GetSubUploadedObjectResponseModel, GetUploadedObjectResponseModel } from "../../../models/object/uploadedObject/get.uploadedObject.response.model";
import { GetListUploadedObjectService } from "../../../services/uploadedObject/get.list.uploadedObject.service";



interface GetUploadedObjectState extends BaseState<GetSubUploadedObjectResponseModel[]>{

}

interface DeleteUploadedObjectPayload {
    bucketKey: string;
    objectKey: string;
  }


let initialState:GetUploadedObjectState={
    data:null,
    error:null,
    loading:false
}


export const getUploadedObjects=createAsyncThunk("uploadedObjects/get",async(request:SecondaryGetUploadedObjectRequest)=>{
    const {bucketKey}=request
    const getListUploadedObjectService=new GetListUploadedObjectService("objects","list")

    if(bucketKey===""){
        return await getListUploadedObjectService.GetAsync()       
    }else{
        return await getListUploadedObjectService.GetByStringParamAsync(bucketKey)     
    }

})

export const getUploadedObjectsBybucketKey=createAsyncThunk("uploadedObjects/get/bucketKey",async(bucketKey:string)=>{
    const getListUploadedObjectService=new GetListUploadedObjectService("objects","list")
    const response=await getListUploadedObjectService.GetByStringParamAsync(bucketKey)
    return response

})


export const getUploadedObjectByUserId=createAsyncThunk("upload",async(uid:string)=>{
    const getListUploadedObjectService=new GetListUploadedObjectService("objects","getList")
    const response=await getListUploadedObjectService.GetByStringParamAsync(uid)
    return response
})


export const getUploadedObjectsSlice=createSlice({
    name:"getUploadedObjectsSlice",
    initialState,
    reducers:{
    },
    extraReducers(builder){
        builder
        .addCase(getUploadedObjects.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(getUploadedObjects.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(getUploadedObjects.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })      
    }
})


export const getUploadedObjectsByBucketKeySlice=createSlice({
    name:"getUploadedObjectsByBucketKeySlice",
    initialState,
    reducers:{
    },
    extraReducers(builder){
        builder
        .addCase(getUploadedObjectsBybucketKey.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(getUploadedObjectsBybucketKey.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(getUploadedObjectsBybucketKey.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })      
    }
})


export const getUploadedObjectsByUserIdSlice=createSlice({
    name:"getUploadedObjectsByUserId",
    initialState,
    reducers:{
        updateWithDeleteObject: (state, action: PayloadAction<DeleteUploadedObjectPayload>) => {
            if (state.data) {
              // Silinen öğeyi listeden çıkar
              state.data = state.data.filter(
                (item) => item.name !== action.payload.objectKey
              );
            }
          },
    },
    extraReducers(builder){
        builder
        .addCase(getUploadedObjectByUserId.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(getUploadedObjectByUserId.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(getUploadedObjectByUserId.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })      
    }
})

export const {updateWithDeleteObject}=getUploadedObjectsByUserIdSlice.actions

export default {
    getUploadedObjectsBucketKeyReducer:getUploadedObjectsByBucketKeySlice.reducer,
    getUploadedObjectsReducer:getUploadedObjectsSlice.reducer,
    getUploadedObjectsByUserIdReducer:getUploadedObjectsByUserIdSlice.reducer
}




