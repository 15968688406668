
import { GetUserCountsWeeklyModel } from "../../models/admin/get.user.counts.model";
import { BaseGetService } from "../base/base.get.service";



export class GetUserCountsWeeklyService extends BaseGetService<GetUserCountsWeeklyModel[]>{
    constructor(controllerName:string="admin", endpointName:string="user/weeklyRegistrations"){
        super(controllerName, endpointName)
    }
}