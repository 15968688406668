import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { UpdateUploadedObjectNameService } from "../../../services/uploadedObject/update.uploadedObject.name.service";
import { UpdateUploadedObjectNameRequestModel, UpdateUploadedObjectNameResponseModel } from "../../../models/object/uploadedObject/update.uploadedObject.name.model";

interface UpdateUploadedObjectName extends BaseState<UpdateUploadedObjectNameResponseModel>{

}

let initialState:UpdateUploadedObjectName={
    data:null,
    error:null,
    loading:false
}

export const updateUploadedObjectName=createAsyncThunk("uploadedObject/updateUploadedObjectName",
    async(request:UpdateUploadedObjectNameRequestModel)=>{
        const service=new UpdateUploadedObjectNameService()
        const response=await service.PostAsync(request)
        return response
})



export const updateUploadedObjectNameSlice=createSlice({
    name:"updateUploadedObjectNameSlice",
    initialState,
    reducers:{
        resetUpdateState:(state)=>{
            state.data = null;
            state.loading = false;
            state.error = null;
        }
    },
    extraReducers(builder){
        builder
        .addCase(updateUploadedObjectName.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(updateUploadedObjectName.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(updateUploadedObjectName.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
        
    }
       
})

export const { resetUpdateState } = updateUploadedObjectNameSlice.actions;
export default updateUploadedObjectNameSlice.reducer