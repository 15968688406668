import { CreateTokenForSampleModelsResponseModel } from "../../models/sample/create.token.for.sample.models.model";
import { BaseGetService } from "../base/base.get.service";




export class CreateTokenForSampleModelsService extends BaseGetService<CreateTokenForSampleModelsResponseModel>{
    constructor(controllerName:string="sample",endpointName:string="token"){
        super(controllerName, endpointName)
    }
}