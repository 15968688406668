import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import {  CreateTokenForSampleModelsResponseModel } from "../../../models/sample/create.token.for.sample.models.model";
import { CreateTokenForSampleModelsService } from "../../../services/sample/create.token.for.sample.models.service";




interface GetTokenForSampleModelState extends BaseState<CreateTokenForSampleModelsResponseModel>{

}

let initialState:GetTokenForSampleModelState={
    data:null,
    loading:false,
    error:null
}


export const fetchTokenForSampleModels=createAsyncThunk("fetch/token",async()=>{
    const service=new CreateTokenForSampleModelsService()
    const response=await service.GetAsync()
    return response
})

export const fetchTokenForSampleModelsSlice=createSlice({
    name:"fetchTokenForSampleModelsSlice",
    initialState,
    reducers:{},
    extraReducers(builder) {
        builder
        .addCase(fetchTokenForSampleModels.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(fetchTokenForSampleModels.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(fetchTokenForSampleModels.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
    },
})

export default fetchTokenForSampleModelsSlice.reducer