import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseState } from "../../../../commons/base.state";
import { ProcessingUploadModel } from "../../../../models/object/uploadedObject/uploadV2/processing.upload.model";
import { PutUploadedObjectRequestModel } from "../../../../models/object/uploadedObject/put.uploadedObject.request.model";
import { StartingUploadModelsService } from "../../../../services/uploadedObject/uploadV2/starting.upload.models.service";




interface StartingUploadModelsState extends BaseState<ProcessingUploadModel[]> {

}


let initialState: StartingUploadModelsState = {
    data: null,
    loading: false,
    error: null
}


export const startUploadModels = createAsyncThunk("uploadedObject/startUploadModels", async (request: PutUploadedObjectRequestModel, thunkAPI) => {
    try {
        const service = new StartingUploadModelsService()
        const response = await service.StartToUploadingAsync(request)
        return response
    }
    catch (err: any) {
        if (!err.response) {
            throw err;
        }
        console.log(err)
        const errorData = err.response.data
        console.log(errorData)

        return thunkAPI.rejectWithValue({
            message: errorData.message
        });
    }
})


export const startUploadModelsSlice=createSlice({
    name:"startUploadModelsSlice",
    initialState,
    reducers:{
        resetStartUploadModelsError:(state)=>{
            state.error = null;
            state.data=null;
            state.loading=false
        }
    },
    extraReducers:builder => {
        builder
           .addCase(startUploadModels.pending, (state) => {
                state.loading = true;
            })
           .addCase(startUploadModels.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
           .addCase(startUploadModels.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?action.payload:action.error
            })
    }
 
})


export const { resetStartUploadModelsError } = startUploadModelsSlice.actions;
export default startUploadModelsSlice.reducer




